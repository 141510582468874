@media screen and (min-width: 1200px) {
  .logo div img {
    width: 110%;
    position: relative; }
  .logo span {
    font-weight: 400;
    font-size: 3.9rem;
    position: absolute; }
  .logo span:first-of-type {
    top: -4.75px;
    left: 164.5px; }
  .logo span:last-of-type {
    top: 10.25px;
    left: 182.5px; }
  .logo p {
    margin-top: 0.5rem;
    position: absolute;
    margin-left: 3px; }
  .tagline {
    margin: 3px 50px 0 50px; }
  .headline h1 {
    font-size: 3.3rem; }
  .headline h1 span {
    font-size: 4.4rem; }
  .telephon1 {
    margin-left: 80px; }
  .telephon2 {
    margin-right: 80px; }
  footer .footer__tel img {
    left: 7px; } }

@media screen and (max-width: 1200px) {
  /*	.container {
		margin-left: 117px;
	}*/
  .headline {
    padding-left: 0px; }
  .basket p {
    margin: 0 0 6px;
    padding: 4px 0 0 17px; }
  .basket__bol {
    padding: 9px 0 9px 42px; }
  #menu__main ul {
    padding: 0px;
    display: block; }
    #menu__main ul li {
      padding: 20px 19px 20px 19px;
      font-size: 2rem; }
  .tagline {
    letter-spacing: 0.1rem; }
  .magnifier__img {
    height: 350px;
    width: 350px; }
    .magnifier__img .zoomWrapper img {
      width: 348px !important;
      height: 348px !important; }
  .zoomContainer .zoomWindow {
    width: 350px !important;
    height: 350px !important; }
  .product__wrapp2 {
    margin-top: -307px; } }

@media screen and (max-width: 992px) {
  .logo {
    font-size: 1.7rem;
    letter-spacing: .2rem; }
    .logo span:first-of-type {
      left: 150px; }
    .logo span:last-of-type {
      left: 167px; }
  .headline {
    padding-left: 15px; }
    .headline h1 {
      font-size: 3.5rem;
      padding: 0 3.5rem;
      line-height: 3.7rem;
      margin-bottom: 8px; }
      .headline h1 span {
        font-size: 4.6rem; }
    .headline .headline__block .tagline {
      margin: 3px 22px 0 24px;
      font-size: 1.4rem;
      line-height: 1.5rem;
      padding: 0rem 6.6rem .1rem 6.6rem;
      letter-spacing: .2rem; }
  .telephon1 {
    margin-left: 11px;
    font-size: 1.5rem; }
  .telephon2 {
    margin-right: 27px;
    font-size: 1.5rem; }
  .number {
    font-size: 1.8rem;
    margin-top: 0px; }
  .rightbar {
    float: left;
    max-width: 120px;
    padding-left: 0; }
  .cityblock {
    padding: 3px 0px 3px 10px; }
  .basket p {
    padding: 9px 0px 0px 10px; }
  .basket__bol {
    background: #fafacc url(/img/basket_img.png) no-repeat 10px;
    /*background-position-x: 17px;*/
    padding: 9px 0px 9px 34px;
    font-size: 1.3rem; }
  .search__only {
    /*white-space: nowrap;*/ }
    .search__only input {
      /*width: 50px;*/
      max-width: 78%;
      border: 0px;
      padding-left: 15px; }
    .search__only button {
      width: 37px; }
      .search__only button .fa {
        left: 4px; }
      .search__only button .fa:nth-child(1) {
        left: 6px; }
  #menu__main ul li:nth-child(1) {
    margin-left: 45px; }
  #menu__main ul li:nth-child(6) {
    margin-left: 225px; }
  #menu__main ul li:nth-child(7) {
    margin-right: 200px; }
  .mainpage {
    margin-top: 145px; }
  .catalog:after {
    width: 150%; }
  .mainpage__slider {
    max-height: 314px; }
    .mainpage__slider div {
      max-height: 314px; }
  .mainpage__slider .slick-track div:nth-child(1) {
    font-size: 2.1rem; }
    .mainpage__slider .slick-track div:nth-child(1) p {
      left: 10px;
      top: 10px; }
  .mainpage__slider .slick-track div:nth-child(2) p {
    left: 20px;
    top: 15px; }
    .mainpage__slider .slick-track div:nth-child(2) p span {
      font-size: 4.8rem; }
  .mainpage__slider .slick-track div:nth-child(3) {
    font-size: 2.5rem; }
    .mainpage__slider .slick-track div:nth-child(3) p {
      left: 20px;
      top: 35px; }
  .mainpage__new .columns_container img {
    max-width: 229px; }
  .mainpage__new_posts2 .columns_container img {
    max-width: 65%; }
  .mainpage__new_posts3 .columns_container img {
    max-width: 100%; }
  .mainpage__new_posts2 .columns_container img:nth-of-type(3) {
    margin-top: 6px; }
  .product__wrapp2 {
    margin-top: 50px; }
  .navtabs {
    margin-top: 50px; }
  footer .footer__col {
    margin: 20px 20px; }
  .footer__col:nth-child(3) {
    margin: 0;
    margin-bottom: 10px; }
  .footer__copyright {
    visibility: hidden; }
  footer .footer__col_wrap {
    border-bottom: 0px; }
  footer .footer__tel hr {
    visibility: visible; }
  footer .footer__copyright_2 {
    display: block; } }

@media screen and (max-width: 768px) {
  header {
    display: none; }
  .header_xs {
    display: block;
    margin-bottom: 32px; }
    .header_xs #menu {
      display: none; }
  .headline {
    padding-left: 0; }
    .headline .headline__block .tagline {
      margin: 3px 32px 0 32px; }
  .rightbar {
    max-width: 200px;
    padding-left: 15px;
    margin-left: 10px; }
  .basket {
    margin-top: 15px;
    font-size: 12px; }
    .basket p {
      padding: 9px 0px 0px 17px; }
  .basket__bol {
    background: #fafacc url(/img/basket_img.png) no-repeat 17px;
    padding: 9px 0px 9px 51px;
    font-size: 1.5rem; }
  .search {
    margin-top: 15px; }
  .search__only {
    /*white-space: nowrap;*/ }
    .search__only input {
      max-width: 80%;
      border: 0px;
      padding-left: 15px; }
    .search__only button .fa {
      position: absolute;
      left: 10px;
      top: 3px;
      /*box-shadow: -2px 1px 1px rgba(0,0,0,0.9)*/ }
    .search__only button .fa:nth-child(1) {
      left: 9px;
      top: 4px; }
  .logo {
    padding-right: 15;
    font-size: 2rem;
    letter-spacing: .2rem; }
    .logo span:first-of-type {
      left: 170px; }
    .logo span:last-of-type {
      left: 187px; }
  #menu__main ul li {
    padding: 20px 15px 20px 15px;
    font-size: 1.7rem; }
  #menu__main ul li::before {
    font-size: 0.9rem; }
  #menu__main ul li:nth-child(6) {
    margin-left: 190px; }
  .mainpage__slider {
    max-height: 278px; }
    .mainpage__slider div {
      max-height: 278px; }
  .mainpage__slider .slick-track div:nth-child(1) {
    font-size: 2rem; }
    .mainpage__slider .slick-track div:nth-child(1) p {
      left: 10px;
      top: 10px; }
  .mainpage__slider .slick-track div:nth-child(2) {
    font-size: 4rem; }
    .mainpage__slider .slick-track div:nth-child(2) p {
      left: 20px;
      top: 15px; }
      .mainpage__slider .slick-track div:nth-child(2) p span {
        font-size: 4rem; }
  .mainpage__slider .slick-track div:nth-child(3) {
    font-size: 2.2rem; }
    .mainpage__slider .slick-track div:nth-child(3) p {
      left: 20px;
      top: 40px; }
  .catalog::after {
    width: 130%; }
  ul.catalog_product li a {
    font-size: 1.6rem; }
  .mainpage__new .columns_container {
    columns: 1; }
  .mainpage__new .columns_container img {
    max-width: 100%; }
  .mainpage__new_posts2 .columns_container img:nth-of-type(2) {
    margin-right: 0; }
  .mainpage__new_posts2 .columns_container img:nth-of-type(3) {
    margin-top: 10px; }
  .number {
    font-size: 2.2rem;
    margin-top: -2px;
    padding: 0; }
  footer .footer__tel hr {
    margin: 8px; } }

@media screen and (max-width: 660px) {
  .logo span:first-of-type {
    left: 154px; }
  .logo span:last-of-type {
    left: 171px; }
  .number {
    font-size: 1.9rem;
    margin-top: -2px;
    padding-left: 10px; }
  #menu__main ul li:nth-child(6) {
    margin-left: 155px; }
  #menu__main ul li:nth-child(7) {
    margin-right: 190px; }
  #menu__main ul li {
    padding: 20px 10px 20px 10px;
    font-size: 1.6rem; }
  .header_xs {
    max-height: 280px; }
  .rightbar {
    max-width: 175px;
    padding-left: 15px;
    margin-left: 10px; }
  .mainpage__slider {
    max-height: 241px; }
    .mainpage__slider div {
      max-height: 241px; }
  footer .footer__tel .footer__number {
    font-size: 1.9rem; }
  footer .footer__tel img {
    left: -41px; } }

@media screen and (max-width: 580px) {
  #menu {
    display: none; }
  .sidebar_left {
    display: none; }
  .mainpage__slider {
    max-height: 314px; }
    .mainpage__slider div {
      max-height: 314px; }
  .logo {
    font-size: 2rem;
    letter-spacing: .1rem; }
  .headline__left {
    display: block; }
  .telephon3 {
    display: none; }
  .header_xs .telephon1 {
    display: block;
    margin-left: 13%;
    margin-right: 30px;
    margin-top: 0;
    text-align: center; }
    .header_xs .telephon1 .number {
      padding-left: 21px; }
  .header_xs .telephon2 {
    display: table;
    text-align: center; }
    .header_xs .telephon2 .number {
      padding-left: 21px; }
  .rightbar {
    margin-bottom: 30px;
    margin-right: 30px; }
  .catalog__new_bottom {
    display: block;
    margin: 50px 0 25px 0; }
  .catalog:after {
    width: 185px; }
  .sidebar__new_bottom {
    display: block;
    max-width: 100%; }
    .sidebar__new_bottom img {
      width: 185px;
      float: left;
      margin-bottom: 25px;
      margin-right: 15px; }
    .sidebar__new_bottom .sidebar__text {
      margin-top: 0; }
    .sidebar__new_bottom .sidebar__advt {
      float: left; }
  footer .footer__col {
    margin: 15px 13px 9px 13px; }
  .footer__col:nth-child(3) {
    margin-top: 15px; }
  footer .footer__tel {
    margin: 5px 0 55px 0;
    /*width: 100%;*/ }
  [class*="col-xs-9"] {
    width: 100% !important; } }

@media screen and (max-width: 500px) {
  .navmobile {
    width: 80%; }
  .headline__left {
    display: none; }
  .logo span:first-of-type {
    left: 170px; }
  .logo span:last-of-type {
    left: 187px; }
  .logo_xs {
    width: 50%;
    float: left;
    margin-left: 10px; }
  .rightbar_xs {
    margin: 30px 0 50px 10px; }
  .header_xs .telephon1 {
    display: block;
    margin-left: 6%;
    margin-right: 15px; }
  .mainpage__slider .slick-track div:nth-child(1) {
    font-size: 1.7rem; }
    .mainpage__slider .slick-track div:nth-child(1) p {
      left: 10px;
      top: 10px; }
  .mainpage__slider .slick-track div:nth-child(2) {
    font-size: 3.5rem; }
    .mainpage__slider .slick-track div:nth-child(2) p {
      left: 10px;
      top: 15px; }
      .mainpage__slider .slick-track div:nth-child(2) p span {
        font-size: 3.5rem; }
  .mainpage__slider .slick-track div:nth-child(3) {
    font-size: 2.1rem; }
    .mainpage__slider .slick-track div:nth-child(3) p {
      left: 10px;
      top: 40px; }
  .mainpage__slider {
    max-height: 250px; }
    .mainpage__slider div {
      max-height: 250px; } }

@media screen and (max-width: 430px) {
  .headline .headline__block .tagline {
    margin: 3px 22px 0 22px;
    font-size: 1.4rem;
    line-height: 1.5rem;
    padding: 0 20px 0 20px;
    letter-spacing: .1rem;
    color: #000;
    background-color: rgba(255, 255, 255, 0); }
  .logo span:first-of-type {
    left: 180px; }
  .logo span:last-of-type {
    left: 197px; }
  .header_xs {
    margin-bottom: 321px; }
  .header_xs .telephon1 {
    float: none;
    width: 188px;
    margin: 10px auto; }
  .header_xs .telephon2 {
    display: block;
    width: 188px;
    margin: 5px auto; }
  .rightbar_xs {
    float: none;
    width: 188px;
    margin: 15px auto; }
  .logo_xs {
    float: none;
    width: 250px;
    margin: 15px auto; }
  .logo div img {
    transform: scale(1.1);
    padding-left: 10px; }
  .mainpage__slider {
    max-height: 210px; }
    .mainpage__slider div {
      max-height: 210px; }
  .comparison h2 {
    font-size: 2rem;
    padding: 0; }
  .sidebar__new_bottom {
    display: block;
    max-width: 185px;
    margin: 0 auto; }
    .sidebar__new_bottom img {
      width: 185px;
      float: none;
      margin-bottom: 10px;
      margin-right: 15px; }
    .sidebar__new_bottom .sidebar__text {
      margin-top: 0; }
    .sidebar__new_bottom .sidebar__advt {
      float: none;
      width: 185px; }
  .navtabs .nav-tabs_mod {
    font-size: 1.4rem; } }

@media screen and (max-width: 376px) {
  .headline h1 {
    padding: 0 20px; }
  .mainpage__slider {
    max-height: 190px; }
    .mainpage__slider div {
      max-height: 190px; }
  .mainpage__slider .slick-track div:nth-child(1) {
    font-size: 1.3rem; }
    .mainpage__slider .slick-track div:nth-child(1) p {
      left: 10px;
      top: 10px; }
  .mainpage__slider .slick-track div:nth-child(2) {
    font-size: 3.1rem; }
    .mainpage__slider .slick-track div:nth-child(2) p {
      left: 10px;
      top: 15px; }
      .mainpage__slider .slick-track div:nth-child(2) p span {
        font-size: 3.1rem; }
  .mainpage__slider .slick-track div:nth-child(3) {
    font-size: 1.6rem; }
    .mainpage__slider .slick-track div:nth-child(3) p {
      left: 10px;
      top: 25px; }
  .comparison h2 {
    font-size: 1.6rem; }
  .comparison .comparison__banner01, .comparison .comparison__banner02, .comparison .comparison__banner03, .comparison .comparison__banner04 {
    margin-right: -18px; }
  .navtabs .nav-tabs_mod {
    font-size: 1.2rem; }
  footer .footer__col ul li {
    display: inline;
    margin: 5px 5px;
    font-size: 1.3rem; }
  footer .footer__col {
    margin-left: 0; }
  footer .footer__tel {
    margin-right: 20px; }
  footer .footer__tel img {
    left: -61px; } }

@media screen and (max-width: 340px) {
  .headline h1 {
    font-size: 3.2rem; }
  .mainpage__slider {
    max-height: 170px; }
    .mainpage__slider div {
      max-height: 170px; }
  .mainpage__slider .slick-track div:nth-child(1) {
    font-size: 1.1rem; }
    .mainpage__slider .slick-track div:nth-child(1) p {
      left: 10px;
      top: 10px; }
  .mainpage__slider .slick-track div:nth-child(2) {
    font-size: 2.9rem; }
    .mainpage__slider .slick-track div:nth-child(2) p {
      left: 10px;
      top: 15px; }
      .mainpage__slider .slick-track div:nth-child(2) p span {
        font-size: 2.8rem; }
  .mainpage__slider .slick-track div:nth-child(3) {
    font-size: 1.4rem; }
    .mainpage__slider .slick-track div:nth-child(3) p {
      left: 10px;
      top: 25px; }
  .comparison h2 {
    font-size: 1.4rem;
    padding: 0; }
  .comparison .comparison__banner01, .comparison .comparison__banner02, .comparison .comparison__banner03, .comparison .comparison__banner04 {
    margin-right: -20px; }
  .navtabs .nav-tabs_mod {
    font-size: 1.1rem; } }
